<template>
  <div>
    <b-modal
      ref="modalDataBookmarksAdd"
      :title-html="$tc('Adicionar marcadores ao {type}', totalSelected, { type: '<b>' + nameTypeReg + '</b>'})"
      cancel-variant="outline-secondary"
      size="lg"
      body-class=""
      :visible="false"
      :ok-only="false"
      :ok-title="$t('Atribuir')"
      :cancel-title="$t('Fechar')"
      ok-variant="primary"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      :cancel-disabled="showProcessing"
      :ok-disabled="showProcessing"
      @ok="saveModal"
      @cancel="hideModal"
    >
      <b-overlay
        :show="showProcessing"
        rounded="sm"
      >
        <validation-observer
          ref="formDataBookmarksAdd"
          #default="{}"
        >
          <b-row>
            <b-col md="12">
              <validation-provider
                #default="{ errors }"
                rules="required"
              >
                <b-form-group
                  :label="$t('Marcadores')"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <v-select
                    v-model="bookmarksSel"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="bookmarks"
                    append-to-body
                    :calculate-position="withPopper"
                    :multiple="true"
                    label="sw075s03"
                    item-text="sw075s03"
                    item-value="sw075s01"
                  >
                    <template #option="{ sw075s03, sw075s04 }">
                      <feather-icon
                        icon="BookmarkIcon"
                        size="18"
                        class="mr-75"
                        :style="{color: sw075s04}"
                      />
                      {{ sw075s03 }}
                    </template>
                    <div slot="no-options">
                      {{ $t('Sem resultados') }}
                    </div>
                  </v-select>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </validation-observer>

        <template #overlay>
          <div class="text-center">
            <p id="cancel-label">
              {{ $t('Aguarde, a processar...') }}
            </p>
          </div>
        </template>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal, BRow, BCol, BFormGroup, BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mixFormGeral } from '../../mixins'

export default {
  components: {
    BModal,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    BOverlay,
  },
  mixins: [mixFormGeral],
  data() {
    return {
      sw025s01: [],
      totalSelected: 0,
      nameTypeReg: '',
      bookmarks: [],
      bookmarksSel: [],
      showProcessing: false,
    }
  },
  methods: {
    async showModal(params) {
      this.resetFields()
      this.sw025s01 = params.sw025s01 || []
      this.totalSelected = params.totalSelected || 0
      this.nameTypeReg = params.nameTypeReg || ''
      this.bookmarks = params.bookmarks || []

      await this.$nextTick()

      await this.$refs.modalDataBookmarksAdd.show()
    },

    async hideModal() {
      this.resetFields()
      this.$refs.modalDataBookmarksAdd.hide()
    },

    resetFields() {
      this.sw025s01 = []
      this.totalSelected = 0
      this.nameTypeReg = ''
      this.bookmarks = []
      this.bookmarksSel = []
      this.showProcessing = false
    },

    async saveModal(evt) {
      evt.preventDefault()

      await this.$refs.formDataBookmarksAdd.validate()
        .then(async valid => {
          if (valid === true) {
            this.showProcessing = true

            await this.$store.dispatch('crm_leads_events/addBookmarksByMultiLeads', {
              sw025s01: this.sw025s01,
              sw076s03: this.bookmarksSel,
            }).then(async msgSuccess => {
              this.showProcessing = false

              if (msgSuccess) {
                await this.showMsgSuccessRequest({ message: msgSuccess }).then(async () => {
                  this.hideModal()
                  await this.$parent.fetchListView()
                })
              }
            }).catch(error => {
              this.showProcessing = false
              this.showMsgErrorRequest(error)
            })
          } else if (valid === false) {
            this.showMsgErrorRequest({ message: this.$t('Tem de escolher pelo menos um marcador') })
          }
        })
    },
  },
}
</script>
